var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.pageLoading
    ? _c(
        "div",
        {
          staticClass: "loading-main",
          on: {
            click: function ($event) {
              $event.stopPropagation()
            },
          },
        },
        [
          _c(
            "van-loading",
            { attrs: { type: "spinner", color: "#1989fa", vertical: "" } },
            [_vm._v("加载中...")]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }