<template>
  <div class="loading-main" v-if="pageLoading" @click.stop>
    <van-loading type="spinner" color="#1989fa" vertical>加载中...</van-loading>
  </div>
</template>
<script>
import { mapMutations, mapState } from 'vuex'
export default {
  name: 'PageLoading',
  components: {},
  props: {},
  data() {
    return {}
  },
  computed: {
    ...mapState(['pageLoading']),
  },
  watch: {},
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  methods: {},
  beforeDestroy() {},
  destroyed() {},
}
</script>
<style scoped lang="less" type="text/less">
.loading-main {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  //   height: 100%;
  //   position: fixed;
  //   flex-direction: column;
  //   justify-content: center;
  //   align-items: center;
}
</style>
